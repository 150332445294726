body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* This is necessary to make Transition work with table row.
 * Used on Entries page.
 */
.table tr.visible.transition {
  display: table-row !important;
}

/* Tabs in lists.
 */
.ui.secondary.pointing.menu .item {
  margin: 0;
}

/* Horizontal list on projects page.
 */
.ui.bulleted.list .list>.item:before,
.ui.bulleted.list>.item:before,
ul.ui.list li:before {
  content: '·';
  color: rgba(0,0,0,.4);
  margin-left: -0.7rem;
}
.ui.horizontal.bulleted.list>.item,
ul.ui.horizontal.bulleted.list li {
  margin-left: 1.2rem;
}

/* Sidebar + Dropdown menus
 */
.ui.sidebar {
  overflow-y: visible !important;
}
.ui.simple.dropdown:hover>.menu {
  top: 0 !important;
}

#root,
#root > div {
  height: 100%;
}

.clickableTableRow {
  cursor: pointer;
}

.fields .braintree-hosted-field{
  line-height: 1.21428571em;
  padding: .67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  color: rgba(0,0,0,.87);
  border-radius: .28571429rem;
  height: 50px;
  margin-bottom: 10px;
}
